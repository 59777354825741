import { projects } from "../data/data";
import { Reveal } from "./Reveal";

export default function Projects() {
  return (
    <>
      <Reveal>
        <section
          id="projects"
          className="text-gray-600 body-font border-l-orange-500"
        >
          <div className="container px-5 mt-24 pb-16 mx-auto">
            <div className="text-center mb-16 text-white">
              <h1 className="text-6xl font-bold mb-4 uppercase">Projects</h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-300">
                Here are some of my previous projects and work! Do you have some
                questions about these projects? contact with me
              </p>
            </div>

            {/* Grid system starts here */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {projects.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="relative block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8 transition duration-300 ease-in-out hover:scale-95"
                  >
                    <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

                    <Reveal>
                      <div className="sm:flex sm:justify-between sm:gap-4">
                        <div>
                          <h3 className="text-lg font-bold text-white sm:text-xl uppercase">
                            {item.name}
                          </h3>
                        </div>

                        <div className="">
                          <img
                            alt="Project"
                            src={item.img}
                            className="h-16 w-18 rounded-lg object-cover shadow-sm"
                          />
                        </div>
                      </div>

                      <dl className="flex gap-4 sm:gap-6">
                        <div className="flex flex-col-reverse">
                          <a
                            href={item.link}
                            target="_blank"
                            className="text-sm font-medium text-gray-400 underline"
                          >
                            Visit
                          </a>
                        </div>
                      </dl>

                      <div className="mt-4">
                        <p className="max-w-[40ch] text-sm text-gray-300">
                          {item.desc}
                        </p>
                      </div>
                    </Reveal>
                  </div>
                );
              })}
            </div>
            {/* End of grid system */}
          </div>
        </section>
      </Reveal>
    </>
  );
}
