import Ads from "./Ads";
import Clients from "./Clients";
import Contact from "./Contact";
import Footer from "./Footer";
import Hero from "./Hero";
import Mkt from "./Mkt";
import Nav from "./Nav";
import Offer from "./Offer";
import Projects from "./Peojects";
import Skills from "./Skills";
import UsedTo from "./UsedTo";

export default function Container() {
  return (
    <div className="container mx-auto">
      <Nav />
      <Hero />
      <Ads />
      <Mkt />
      <Offer />
      <Contact />
      {/* <Skills /> */}
      {/* <UsedTo /> */}
      <Projects />
      <Clients />
      <Footer />
    </div>
  );
}
