import me from "../assets/me.webp";
import c1 from "../assets/hal-gatewood-xzzgY__zX8A-unsplash.webp";
import c2 from "../assets/sergey-zolkin-_UeY8aTI6d0-unsplash.webp";
import { Reveal } from "./Reveal";

export default function Ads() {
  const items = [
    {
      id: 1,
      imgSrc: me,
      title: "Developer",
      subtitle: "Ghazi",
      isMain: false,
      style: "h-64 w-full object-cover opacity-75 transition-opacity group-hover:opacity-50",
      spanText: "",
    },
    {
      id: 2,
      imgSrc: c1,
      // title: "Analysis",
      // subtitle: "Planning",
      isMain: false,
      style: "h-64 w-full object-cover transition duration-500 group-hover:opacity-90",
      spanText: "Analysis, Planning, Design",
    },
    {
      id: 3,
      imgSrc: c2,
      // title: "Passion",
      // subtitle: "Creativity",
      isMain: true,
      style: "h-64 w-full object-cover transition duration-500 group-hover:opacity-90",
      spanText: "Passion, creativity, achievement",
    },
  ];

  return (
    <Reveal>
      <section id="vision" className="py-16 bg-gray-900">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <header className="text-center mb-12">
            <h2 className="text-5xl font-bold text-white uppercase">
              My Vision
            </h2>
            <p className="text-lg text-gray-400 mt-2">
              A glimpse into my creative journey
            </p>
          </header>

          <div className="flex flex-col sm:flex-row sm:justify-center gap-4">
            {items.map((item) => (
              <div
                key={item.id}
                className="relative block overflow-hidden rounded-lg shadow-lg bg-black transition-transform duration-300 transform sm:w-1/3 w-full hover:scale-105"
              >
                <a href="#" className="group relative block">
                  <img src={item.imgSrc} alt="" className={item.style} />
                  <div className="relative p-4 sm:p-6 lg:p-8">
                    <p
                      style={{ color: "#52CFE4" }}
                      className="text-sm font-medium uppercase tracking-widest"
                    >
                      {item.title}
                    </p>

                    <p className="text-xl font-bold text-white sm:text-2xl">
                      {item.subtitle}
                    </p>

                    <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                      {item.spanText && (
                        <span
                          style={{ background: "black", color: "#52CFE4" }}
                          className="mt-2 inline-block bg-black px-5 py-2 text-xs font-medium uppercase tracking-wide rounded"
                        >
                          {item.spanText}
                        </span>
                      )}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Reveal>
  );
}
